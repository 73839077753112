import React, {useRef} from 'react'
import gsap from 'gsap'
import {useGSAP} from '@gsap/react'



const ComingSoon = () => {

    const nameRef = useRef()
    const spanRef = useRef()

    useGSAP(()=>{
        gsap.fromTo(nameRef.current, {

            opacity: 0,
            y: 100

            }, {

            opacity: 1, 
            y: 0,
            duration: 1.5,
            ease: "power4.out",

        })

        gsap.fromTo(spanRef.current, {
            opacity: 0
        }, {
            opacity: 1,
            duration: 1,
            delay: 0.5
            
            
        })
    })

  return (
    <div className='comingsoon center-flex'>
        <div ref={nameRef}>The Brewing Machine</div>
        <span ref={spanRef}>Coming Soon</span>
    </div>
  )
}

export default ComingSoon