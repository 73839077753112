import logo from './logo.svg';
import './App.css';
import ComingSoon from './ComingSoon';


import styled from 'styled-components';

const MainWrapper = styled.div`
 
`


function App() {
  return (
    <MainWrapper className="App center-flex">
      <ComingSoon />
    </MainWrapper>
  );
}

export default App;
